import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import css from './index.module.css';
import InputMask from 'react-input-mask';

import Logo from '@assets/images/logo.svg';
import VK from '@assets/images/vk.svg';
import YA from '@assets/images/ya.svg';
import LeeCyber from '@assets/images/leecyber.svg';
import SEO from "@components/seo";
import { toast } from "sonner";

export default function Authorization () {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [authUrl, setAuthUrl] = useState('');

  const [step, setStep] = useState<number>(0);
  const [uuid, setUuid] = useState<string>('');
  const [phone, setPhone] = useState('');
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handlePhone = (event: any) => {
    setPhone(event.target.value);
  };

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("code")

  if (term && !isRequestSent) {
    setIsRequestSent(true);
    axios.post(`${import.meta.env.VITE_API_URL}/user/auth/leecyber`, { id: term }, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        if (response.data.status != 0) {
            setUuid(response.data.id);
            setStep(response.data.status);
            setEmail(response.data.email);
            setPhone(response.data.phone);
            setFirstName(response.data.firstName);
        } else if (response.data.token && response.data.status == 0) {
            localStorage.setItem('authToken', response.data.token);
            window.location.replace("/");
        }
    })
    .catch(error => {
        console.log(error);
        toast.error('Данные используются аккаунтом, не связанным с LeeCyber')
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('phone', phone);

    axios.post(`${import.meta.env.VITE_API_URL}/user/auth`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(_ => {
        setStep(1);
    })
    .catch(error => {
        console.log(error);
    });
  };

  const handleSubmitReg = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('phone', phone);
    formData.append('status', step.toString());
    formData.append('uuid', uuid);

    axios.post(`${import.meta.env.VITE_API_URL}/user/reg`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        localStorage.setItem('authToken', response.data.token);
        window.location.replace("/");
    })
    .catch(error => {
        console.log(error);
    });
  };

  useEffect(() => {
    const generateCodeVerifier = () => {
      const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      let codeVerifier = "";
      const length = Math.floor(Math.random() * (128 - 43 + 1)) + 43; // Длина от 43 до 128 символов

      for (let i = 0; i < length; i++) {
        codeVerifier += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return codeVerifier;
    };

    const generateCodeChallenge = (codeVerifier: string) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(codeVerifier);

      return crypto.subtle.digest('SHA-256', data).then(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const base64String = btoa(String.fromCharCode(...hashArray));
        return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
      });
    };

    const generateState = () => {
      const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
      let state = "";

      for (let i = 0; i < 32; i++) {
        state += charset.charAt(Math.floor(Math.random() * charset.length));
      }

      return state;
    };

    const clientId = "52997173";
    const redirectUri = "https://artcons.io/auth/vk";
    const codeVerifier = generateCodeVerifier();
    const state = generateState();

    localStorage.setItem('codeVerifier', codeVerifier);

    generateCodeChallenge(codeVerifier).then(codeChallenge => {
      const authorizationUrl = `https://id.vk.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
      setAuthUrl(authorizationUrl);
    });
  }, []);

  return (
    <div className={css.container}>
        <SEO 
            title="Авторизация"
            description="Авторизация"
            ogUrl="https://artcons.io/"
        />

        <div className={css.component}>
            <img src={Logo} alt="Logo" />
            
            {step === 0 &&
                <form className={css.form} onSubmit={handleSubmit}>
                    <a className={css.head}>Добро пожаловать!</a>
                    <InputMask placeholder="Введите номер телефона" mask="+7 (999) 999-99-99" value={phone} onChange={handlePhone} required />
                    <div className={css.btns}>
                        <button type="submit">Продолжить</button>
                        <div className={css.services}>
                            <a href="https://oauth.yandex.ru/authorize?response_type=code&client_id=6795a5efd4cc4243bd4b857696fb79f4" type="submit" className={css.serviceBtn}><img src={YA} alt="yandex" className={css.service} style={{ width: 25 }}/></a>
                            <a href="https://dash.leecyber.com/authorize/e23d1cd8-76bf-40f5-b1ef-57bcddd51959" className={css.serviceBtn}><img src={LeeCyber} alt="leecyber" className={css.service} style={{ width: 22 }}/></a>
                            <a href={authUrl} type="submit" className={css.serviceBtn}><img src={VK} alt="vk" className={css.service} style={{ width: 22 }}/></a>
                        </div>
                    </div>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>

                    {/* <div className={css.pre}>
                        <h1>Функциональность ограничена</h1>
                        <a>Мы проводим тестирование портала, доступ по заявкам.</a>
                    </div> */}
                </form>   
            }

            {step === 1 &&
                <form className={css.form}>
                    <a className={css.head}>Вход в личный кабинет</a>
                    <SmsCode phone={phone} setStep={setStep} setUuid={setUuid} />
                    <button type="submit">Повторить отправку</button>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>
                </form>
            }

            {step >= 2 &&
                <form className={css.form} onSubmit={handleSubmitReg}>
                    <a className={css.head}>Давайте познакомимся</a>

                    <input name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Как Вас зовут?" required/>
                    {step === 2.1 && <input name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Ваш номер телефона" required/>}
                    <input name="projectName" placeholder="Название проекта, компании" required/>
                    <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Ваша электронная почта" required/>
                    <input name="promocode" placeholder="Промокод (если есть)" />

                    <button type="submit">Сохранить изменения</button>
                    <a className={css.foot}>Нажимая кнопку “продолжить” я соглашаюсь с политикой обработки персональных данных</a>
                </form>   
            }

            <div className={css.legal}>
                <a href="https://docs.artcons.io/legal/privacy">Условия сервиса</a>
                <a href="https://docs.artcons.io/legal/agreement">Политика обработки данных</a>
            </div>
        </div>
    </div>
  )
}

function SmsCode({ phone, setStep, setUuid }: { phone: string, setStep: any, setUuid: any }) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [codeEntered, setCodeEntered] = useState<boolean>(false);
    const inputRefs = useRef<any>([]);

    const handleChange = (e: any, index: number) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value.slice(0, 1);
            setCode(newCode);

            if (index < 5 && value !== '') {
                inputRefs.current[index + 1].focus();
            }

            if (newCode.every((digit) => digit !== '')) {
                setCodeEntered(true);
            } else {
                setCodeEntered(false);
            }
        }
    };

    const handleBackspace = (e: any, index: number) => {
        if (e.keyCode === 8 && index > 0 && code[index] === '') {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
            setCodeEntered(false);
        }
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < 6; i++) {
            inputs.push(
                <input
                    key={i}
                    type="text"
                    maxLength={1}
                    value={code[i]}
                    onChange={(e) => handleChange(e, i)}
                    onKeyDown={(e) => handleBackspace(e, i)}
                    ref={(el) => (inputRefs.current[i] = el)}
                />
            );
        }
        return inputs;
    };

    const sendCode = () => {
        const enteredCode = code.join('');
        axios.post(`${import.meta.env.VITE_API_URL}/user/auth/code`, { phone: phone, code: enteredCode })
            .then(response => {
                if (response.data.status != 0) {
                    setUuid(response.data.id);
                    setStep(response.data.status);
                } else {
                    localStorage.setItem('authToken', response.data.token);
                    window.location.replace("/");
                }
            })
            .catch(() => {
                toast.error('Неверно введен код авторизации');
            });
    };

    useEffect(() => {
        if (codeEntered) {
            sendCode();
            setCodeEntered(false);
        }
    }, [codeEntered]);

    return (
        <div className={css.enterCode}>
            {renderInputs()}
        </div>
    );
}
