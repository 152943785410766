import { FC, useState } from "react";
import css from '../index.module.css';
import Popup from "@components/popup";
import { toast } from "sonner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Modal {
  showModal: boolean; 
  setShowModal: any;
}

export const ContactUs:FC<Modal> = ({ showModal, setShowModal }) => {
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('type', 'order');

    axios.post(`${import.meta.env.VITE_API_URL}/site/form`, formData, {
      headers: {
          'Content-Type': 'application/json',
      }})
      .then(async (response) => {
        toast.success('Заявка отправлена, спасибо!');
        setShowModal(false);
      });
  };

  return (
      <Popup showPopup={showModal} setShowPopup={setShowModal}>
        <h5 style={{ fontSize: '20px', marginBottom: 15, marginTop: 5 }}>Отправить заявку</h5>

        <form onSubmit={handleSubmit} className={css.form}>
            <input name="name" placeholder="Как Вас зовут?*" required />
            <input name="phone" placeholder="Ваш телефон для обратной связи*" required />
            <input name="email" placeholder="Ваша корпоративная почта*" required />
            <input name="company" placeholder="Компания" />

            <div className={css.checkbox}>
                <input type="checkbox" name="agree" required checked />
                <label htmlFor="agree">Я даю своё согласие ArtCons и его аффилированным лицам на обработку указанных мной в анкете персональных данных на условиях, изложенных в <a href="https://docs.artcons.io/legal/privacy">Политике конфиденциальности</a> в целях получения обратной связи.</label>
            </div>

            <button type="submit">Отправить заявку</button>
        </form>
      </Popup>
  );
}
