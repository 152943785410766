import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from '../index.module.css';
import { toast } from 'sonner';
import axios from 'axios';

export default function Newsletter() {
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('type', 'newsletter');
    
        axios.post(`${import.meta.env.VITE_API_URL}/site/form`, formData, {
        headers: {
            'Content-Type': 'application/json',
        }})
        .then(async (response) => {
            toast.success('Вы подписаны на рассылку!');
        });
    };
        
    return (
        <div className={css.newsletter}>
            <div className={css.newsletterText}>
                <h3>Подпишитесь на нашу рассылку</h3>
                <a>Подпишитесь, чтобы получать эксклюзивную информацию, объявления и специальные предложения.</a>
            </div>

            <form className={css.newsletterForm} onSubmit={handleSubmit}>
                <input type="email" placeholder="Ваш email" />
                <button type="submit">Подписаться</button>
            </form>
        </div>
    );
}