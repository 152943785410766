import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";

import * as Types from '../../types';
import LandingHeader from "./components/header";
import LandingFooter from "./components/footer";
import FAQItem from "./components/faq";
import Newsletter from "./components/newsletter";
import BlogMini from "@components/blogMini";
import { ContactUs } from "./components/contact";

export default function Blog() {
    const [showModal, setShowModal] = useState(false);
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_URL}/blog`, {
          headers: {
            'Content-Type': 'application/json',
          }})
          .then(response  => {
            setBlog(response.data);
          })
          .catch(error => {
            setBlog([]);
        });
    }, []);

    return (
        <>
            <ContactUs showModal={showModal} setShowModal={setShowModal} />
            <LandingHeader />
            
            <div className={css.content}>
                <div className={css.blog} style={{ margin: 0 }}>
                    <h2 style={{ marginBottom: 20 }}>Блог</h2>

                    <div className={css.newsListFull}>
                        {blog.map((item: any) => (
                            <BlogMini
                                id={item.id}
                                image={item.image}
                                by={item.by}
                                createdAt={item.createdAt}
                                name={item.name}
                                isFull={true}
                            />
                        ))}

                        {blog.length === 0 && <a>Здесь пока ничего нет.</a>}
                    </div>
                </div>
            </div>
            <LandingFooter />
        </>
    )
}