import { FC } from "react";
import css from './index.module.css';
import * as Types from '../../types';
import { useNavigate } from "react-router-dom";

interface Container {
  id: string;
  image: string;
  by: string;
  createdAt: string;
  name: string;
  isFull?: boolean;
}

const BlogMini:FC<Container> = ({ id, image, by, createdAt, name, isFull }) => {
  const navigate = useNavigate();

  function convertToDate(isoDate: string) {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }

  return (
    <div className={css.newsBlock} key={id} onClick={() => navigate(`/blog/${id}`)} style={{ width: isFull ? '426.5px' : ''}}>
        <img src={`https://cdn-a.leecyber.com/news/${image}`} />
        <a>{by} • {convertToDate(createdAt)}</a>
        <h3>{name}</h3>
    </div>
  );
};

export default BlogMini;