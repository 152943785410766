import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";

import * as Types from '../../types';
import LandingHeader from "./components/header";
import LandingFooter from "./components/footer";
import FAQItem from "./components/faq";
import Newsletter from "./components/newsletter";
import BlogMini from "@components/blogMini";
import { ContactUs } from "./components/contact";

export default function Landing() {
    const [showModal, setShowModal] = useState(false);
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_URL}/blog`, {
          headers: {
            'Content-Type': 'application/json',
          }})
          .then(response  => {
            setBlog(response.data);
          })
          .catch(error => {
            setBlog([]);
        });
    }, []);

    return (
        <>
            <ContactUs showModal={showModal} setShowModal={setShowModal} />
            <LandingHeader />
            
            <div className={css.content}>
                <div className={css.firstBlock}>
                    <div className={css.firstBlockText}>
                        <h1><u>Сервер-сайд</u> трекинг для вашего сайта</h1>
                        <a>ArtCons - это универсальное решение для серерного отслеживания данных.</a>
                    </div>

                    <div className={css.firstBlockButtons}>
                        <Link to="/auth" className={css.btnConnect} style={{ margin: 0 }}>Попробовать сейчас <i className="fi fi-rr-arrow-up-right" /></Link>
                        <Link to="/" className={css.callback} onClick={() => setShowModal(true)}>Свяжитесь со мной</Link>
                    </div>

                    <div className={css.options}>
                        <div className={css.option}>    
                            <h3>Быстрая настройка</h3>
                            <a>Настроить теги на стороне сервера для Google Tag Manager можно за несколько минут.</a>
                        </div>

                        <div className={css.option}>    
                            <h3>Российские серверы</h3>
                            <a>Избегайте передачи данных за пределы России и соблюдайте законодательство РФ.</a>
                        </div>

                        <div className={css.option}>    
                            <h3>Быстрая поддержка</h3>
                            <a>Проконсультируем по вопросам интеграции, настройки и использования сервиса.</a>
                        </div>
                    </div>
                </div>

                <div className={css.pricing}>
                    <h2>Тарифные планы</h2>
                    
                    <div className={css.pricingCards}>
                        <div className={css.pricingCard}>
                            <a>Basic</a>
                            <h3>2 500 ₽/мес</h3>
                            <p>Для небольших проектов</p>

                            <Link to="/auth" className={css.btnConnect}>Подключить <i className="fi fi-rr-arrow-up-right" /></Link>

                            <div className={css.pricingCardFeatures}>
                                <a><i className="fi fi-rr-refresh"></i> Обновления</a>
                                <a><i className="fi fi-rr-user-headset"></i> Техническая поддержка</a>
                                <a><i className="fi fi-rr-microchip"></i> 2 x 3.3 ГГц CPU</a>
                                <a><i className="fi fi-rr-memory"></i> 2 ГБ ОЗУ</a>
                                <a><i className="fi fi-rr-disk"></i> 40 ГБ NVMe</a>
                                <a><i className="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
                            </div>
                        </div>

                        <div className={css.pricingCard}>
                            <a>Standart</a>
                            <h3>5 000 ₽/мес</h3>
                            <p>Для средних компаний</p>

                            <Link to="/auth" className={css.btnConnect}>Подключить <i className="fi fi-rr-arrow-up-right" /></Link>

                            <div className={css.pricingCardFeatures}>
                                <a><i className="fi fi-rr-refresh"></i> Обновления</a>
                                <a><i className="fi fi-rr-user-headset"></i> Техническая поддержка</a>
                                <a><i className="fi fi-rr-microchip"></i> 4 x 3.3 ГГц CPU</a>
                                <a><i className="fi fi-rr-memory"></i> 8 ГБ ОЗУ</a>
                                <a><i className="fi fi-rr-disk"></i> 80 ГБ NVMe</a>
                                <a><i className="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
                            </div>
                        </div>

                        <div className={css.pricingCard} id="pricing">
                            <a>Business</a>
                            <h3>10 000 ₽/мес</h3>
                            <p>Для крупных компаний</p>

                            <Link to="/auth" className={css.btnConnect}>Подключить <i className="fi fi-rr-arrow-up-right" /></Link>

                            <div className={css.pricingCardFeatures}>
                                <a><i className="fi fi-rr-refresh"></i> Обновления</a>
                                <a><i className="fi fi-rr-user-headset"></i> Техническая поддержка</a>
                                <a><i className="fi fi-rr-microchip"></i> 8 x 3.3 ГГц CPU</a>
                                <a><i className="fi fi-rr-memory"></i> 16 ГБ ОЗУ</a>
                                <a><i className="fi fi-rr-disk"></i> 160 ГБ NVMe</a>
                                <a><i className="fi fi-rr-cloud-back-up"></i> 1 Гбит/с*</a>
                            </div>
                        </div>

                        <div className={css.pricingCard}>
                            <a>Enterprise</a>
                            <h3>Индивидуално</h3>
                            <p>Для корпораций</p>

                            <a className={css.btnConnect} onClick={() => setShowModal(true)}>Связаться <i className="fi fi-rr-arrow-up-right" /></a>

                            <div className={css.pricingCardFeatures}>
                                <a><i className="fi fi-rr-refresh"></i> Обновления</a>
                                <a><i className="fi fi-rr-user-headset"></i> Техническая поддержка</a>
                                <a><i className="fi fi-rr-boss"></i> Менеджер</a>
                                <a><i className="fi fi-rr-database"></i> Подбор сервера</a>
                            </div>
                        </div>
                    </div>
                    <a style={{ margin: 0, fontSize: 13, color: '#000000f' }}>На серверах в Нидерландах установленная пропускная способность — 200 Мбит/c.</a>
                </div>

                <div className={css.blog}>
                    <h2 style={{ marginBottom: 20 }}>Последние новости</h2>

                    <div className={css.newsList}>
                        {blog.slice(0, 3).map((item: any) => (
                            <BlogMini
                                id={item.id}
                                image={item.image}
                                by={item.by}
                                createdAt={item.createdAt}
                                name={item.name}
                            />
                        ))}

                        {blog.length === 0 && <a>Новостей нет.</a>}
                    </div>
                </div>
                
                <div className={css.faq}>
                    <h2>Часто задаваемые вопросы</h2>

                    <FAQItem 
                        question="Могут ли заблокировать контейнер?" 
                        answer="Нет, мы используем официальный API Google, который не блокируется. Вы также можете установить контейнер за рубежом, дабы обезопасить себя от санкций." 
                    />

                    <FAQItem 
                        question="Можно дать доступ коллегам?" 
                        answer="Да, вы можете добавить пользователя в организацию и дать доступ к сервису." 
                    />

                    <FAQItem 
                        question="Есть ли ограничение по запросам?" 
                        answer="Нет. Мы устанавливаем Google Tag Manager на ваш сервер, там нет никаких ограничений, кроме серверных характеристик." 
                    />
        
                    <FAQItem 
                        question="Мне помогут?" 
                        answer="Да, в нашей команде — профессионалы. Поможем установить, настроить и решить возникающие проблемы." 
                    />

                    <FAQItem 
                        question="Как оплатить?" 
                        answer="Вам нужно зарегистрироваться в ArtCons, перейти в раздел «Кошелёк» и пополнить счёт любым удобным способом." 
                    />

                    <FAQItem 
                        question="Как зарегистрироваться?" 
                        answer="Зарегистрироваться в ArtCons можно с помощью вашего аккаунта LeeCyber.ID, VK, Yandex или по номеру телефона." 
                    />
                </div>  

                <Newsletter />
            </div>

            <LandingFooter />
        </>
    )
}