import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from '../index.module.css';
import Logo from '@assets/images/logo.svg';

import SEO from "@components/seo";

export default function LandingFooter() {
    return (
        <footer className={css.footer}>
            <div className={css.footer1}>
                <img src={Logo} alt="ArtCons" className={css.logo} />
                <a href="mailto:info@artcons.ru">info@artcons.ru</a>
            </div>

            <div className={css.footer2}>
                <div className={css.footer2Links}>
                    <a href="https://docs.artcons.io/legal/privacy">Политика обработки данных</a>
                    <a href="https://docs.artcons.io/legal/agreement">Публичная оферта</a>
                </div>

                <a>ИП Артиков Т.Д. ИНН 501817999160</a>
            </div>
        </footer>
    )
}