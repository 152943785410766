import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from '../index.module.css';
import Logo from '@assets/images/logo.svg';

import SEO from "@components/seo";

export default function LandingHeader() {
    return (
        <header className={css.header}>
            <div className={css.leftMenu}>
                <Link to="/"><img src={Logo} alt="ArtCons" className={css.logo} /></Link>

                <div className={css.menu}>
                    <a href="/#pricing">Цены</a>
                    <Link to="/blog">Блог</Link>
                    <a href="https://docs.artcons.io">Документация</a>
                </div>
            </div>

            <div className={css.buttons}>
                <a href="https://t.me/artconsio" target="_blank" className="dev-hidden-mobile"><i className="fi fi-brands-telegram"></i></a>
                <Link to={`/auth`} className={`${css.regBtn} dev-hidden-mobile`}>Зарегистрироваться</Link>
            </div>
        </header>
    )
}