import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import css from './index.module.css';
import InputMask from 'react-input-mask';

import Logo from '@assets/images/logo.svg';
import SEO from "@components/seo";
import { toast } from "sonner";

export default function AuthorizationVK () {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("code")
  const deviceId = queryParams.get("device_id")

  if (term && !isRequestSent) {
    setIsRequestSent(true);
    
    axios.post(`${import.meta.env.VITE_API_URL}/user/auth/vk`, { code: term, deviceId: deviceId, codeVerifier: localStorage.getItem('codeVerifier') }, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        localStorage.setItem('authToken', response.data.token);
        window.location.replace("/");
    })
    .catch(error => {
        console.log(error);
        toast.error('Данные используются аккаунтом, не связанным с VK')
    });
  }

  return (
    <div className={css.container}>
        <SEO 
            title="Авторизация"
            description="Авторизация"
            ogUrl="https://artcons.io/"
        />

        <div className={css.component}>
            <img src={Logo} alt="Logo" />

            <form className={css.form}>
                <a className={css.head}>Проверяем данные...</a>
                <a className={css.foot}>Нам нужно буквально пару секунд, пожалуйста, подождите.</a>
            </form>

            <div className={css.legal}>
                <a href="https://docs.artcons.io/legal/privacy">Условия сервиса</a>
                <a href="https://docs.artcons.io/legal/agreement">Политика обработки данных</a>
            </div>
        </div>
    </div>
  )
}